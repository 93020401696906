export function getWorkListSpanwPieces(start, end, worker, isEmployee) {
    return new Promise((resolve, reject) => {
        this.doAction(
            this.WEB_REQUEST_URL + 'get_work_list_span_ungrouped',
            this.createBundle(
                'get_work_list_span_ungrouped',
                { start, end, worker },
                this.GET,
                () => {

                },
                (result) => {
                    resolve(result)
                },
                (error) => {
                    reject(error)
                },
                isEmployee
            )
        )
    })
}
