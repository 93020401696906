export function getRecipeList(search, isEmployee, filters, sorts, page = -1, items = 10, articleId) {
    return new Promise((resolve, reject) => {
        let { getRecipeListRequest, getRecipeListError, getRecipeListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_recipe_list',
            this.createBundle(
                'get_recipe_list',
                { search: search ? search : '', articleId: articleId ? articleId : '', filters, sorts, page, items },
                this.POST,
                getRecipeListRequest,
                (result) => {
                    getRecipeListResult(result)
                    resolve(result)
                },
                (error) => {
                    getRecipeListError(error)
                    reject(error)
                }
            )
        )
    })
}
