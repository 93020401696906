import { Switch } from '@chakra-ui/switch'
import { Jobs, Machines } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import ReactDatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { Button, Form, Icon, Input, Label, Select, Step } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { SEARCH_ACTIONS } from '../actions/search'
import { Numpad } from '../components'
import DateTimePicker from 'react-datetime-picker'
import TimePicker from 'react-time-picker'
import WorkOrders from 'printing/WorkOrders'

class Steps extends Component {
    constructor(props) {
        super(props)

        let updateSearch = debounce(300, false, (search) => {
            this.props.searchResult(search)
        })

        this.state = {
            ...this.defaultState(),
            workDate: moment(),
            updateSearch,
        }
    }

    componentDidMount() {
        //this.state.network.getDetailedJobList(this.state.page, this.state.items);
        setTimeout(() => {
            this.props.network.getMappingData(true)
        }, 2000)
    }

    prev() {
        let { step } = this.state

        this.clearSearch()
        this.setState({ step: Math.max(0, step - 1) })
    }

    next() {
        let { step } = this.state

        this.clearSearch()
        this.setState({ step: Math.min(2, step + 1) })
    }

    clearSearch() {
        this.setState({
            search: '',
        })
        this.props.searchResult('')
    }

    defaultState() {
        return {
            step: 0,
            search: '',
            job: {},
            machine: {
                machineId: 1,
                machineImage: '',
                machineName: 'Nessuna macchina',
                machineHourlyRate: '0.00',
                machineType: 1,
            },
            description: '',
            workerHours: 0,
            machineHours: 0,
            workerMinutes: 0,
            machineMinutes: 0,
            workStartDate: new Date(),
            workStartTime: '09:00',
            timePickerValue: moment().format('YYYY-MM-DD HH:mm:ss'),
            startCycles: 0,
            endCycles: 0,
            startPieces: 0,
            endPieces: 0,
            badPieces: 0,
            workOrders: {},
            workOrderMap: {},
            attivita: 'AT1'
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { workday } = this.props
        if (this.state.workDate.format('YYYY-MM-DD') !== workday.data.date.format('YYYY-MM-DD')) {
            this.setState({
                workDate: moment(workday.data.date),
            })
        }

        if (this.props.creatingWork !== prevProps.creatingWork) {
            if (!this.props.creatingWork) {
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                        ...this.defaultState(),
                    })
                } else {
                    if (this.props.createStatus.error && this.props.createStatus.error.response.status === 409) {
                        this.setState({
                            status: 'error',
                            errorMessage: 'Dati inseriti errati',
                        })
                    } else {
                        this.setState({
                            status: 'error',
                            errorMessage: '',
                        })
                    }
                }
            }
        }
    }

    save() {
        let { network, worker } = this.props
        let {
            job,
            machine,
            description,
            workDate,
            workerHours,
            machineHours,
            workerMinutes,
            machineMinutes,
            workStartDate,
            workStartTime,
            startCycles,
            endCycles,
            startPieces,
            endPieces,
            badPieces,
            badReason,
            workOrders,
            workOrderMap,
            attivita
        } = this.state

        const workStart = moment(workStartDate)
            .startOf('day')
            .add(workStartTime.split(':')[0], 'hours')
            .add(workStartTime.split(':')[1], 'minutes')
            .format('YYYY-MM-DD HH:mm:ss')

        let jobCodes = []
        let workOrderIds = []
        let mainJobCode = null

        for (const workOrderId in workOrders) {
            const workOrder = workOrderMap[workOrderId]
            /* eslint-disable */
            console.log('Steps workorders is ', workOrder, workOrderId, workOrderMap)
            if (!mainJobCode) {
                mainJobCode = workOrder.jobCode
            }
            jobCodes.push(workOrder.jobCode)
            workOrderIds.push(workOrderId)
        }

        let work = {
            workerId: worker,
            jobCode: mainJobCode,
            workOrderIds: workOrderIds,
            jobCodes: jobCodes,
            workDate: workDate.format('YYYY-MM-DD'),
            description,
            workerHours: workerHours,
            machineHours: machineHours,
            workerMinutes: workerMinutes,
            machineMinutes: machineMinutes,
            machineId: machine.machineId,
            workStart,
            startCycles: parseInt(startCycles),
            endCycles: parseInt(endCycles),
            startPieces: parseInt(startPieces),
            endPieces: parseInt(endPieces),
            badPieces: parseInt(badPieces),
            badReason,
            attivita
        }

        network.createWork(work, true)
    }

    render() {
        let {
            step,
            job,
            description,
            machine,
            workerHours,
            machineHours,
            workStartDate,
            workStartTime,
            timePickerValue,
            startCycles,
            endCycles,
            startPieces,
            endPieces,
            badPieces,
            badReason,
            attivita
        } = this.state
        let { worker } = this.props

        let content = <div />

        //if (this.state.getDetailedJobList.fetching) {
        //	content = <div>Loading</div>;
        //} else if (this.state.getDetailedJobList.data.data) {
        content = [
            <Step.Group key="stepgroup">
                <Step active={step === 0} completed={step > 0}>
                    <Icon name="book" />
                    <Step.Content>
                        <Step.Title>Articoli</Step.Title>
                        <Step.Description>{job.jobCode ? job.jobCode : 'Quali articoli?'}</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 1} completed={step > 1} disabled={step < 1}>
                    <Icon name="hdd" />
                    <Step.Content>
                        <Step.Title>Macchina</Step.Title>
                        <Step.Description>{machine.machineId ? machine.machineName : 'Quale macchina?'}</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 2} completed={step > 2} disabled={step < 2}>
                    <Icon name="clock" />
                    <Step.Content>
                        <Step.Title>Ore</Step.Title>
                        <Step.Description>Quante ore lavorate?</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>,
            step < 2 ? (
                <Input
                    style={{ width: '100%' }}
                    icon={this.state.search.length > 0 ? <Icon name="delete" link onClick={() => this.clearSearch()} /> : <Icon name="search" />}
                    placeholder="Cerca..."
                    value={this.state.search}
                    onChange={(e, data) => {
                        let text = data.value
                        this.state.updateSearch(text)
                        this.setState({ search: text })
                    }}
                />
            ) : null,
            <View column fullw fullh style={{ overflowY: 'scroll', marginTop: 0 }} key="stepcontent">
                {
                    {
                        0: (
                            <View column fullh style={{ height: '95%' }}>
                                <WorkOrders
                                    workerId={worker}
                                    noActions
                                    noBarcode
                                    sub
                                    searchable
                                    selectable
                                    selected={job}
                                    style={{ height: '100%', overflowY: 'auto' }}
                                    onChangeSelection={(workOrders, workOrderId, object, checked) => {
                                        const { workOrderMap } = this.state
                                        workOrderMap[workOrderId] = object
                                        this.setState({ workOrders, workOrderMap })
                                    }}
                                    isEmployee
                                    hideInputs
                                    hideWorkOrderInternal
                                    hideWorkOrderName
                                />
                            </View>
                        ),
                        1: (
                            <View column fullh style={{ height: '95%' }}>
                                <Machines
                                    workerId={worker}
                                    noActions
                                    isEmployee
                                    selectable
                                    showNoMachine
                                    sub
                                    selected={machine}
                                    style={{ height: '100%', overflowY: 'auto' }}
                                    onChange={(machine) => {
                                        this.next()
                                        // if (machine.activeLotId) {
                                        //     this.props.network.getLot(machine.activeLotId, true).then((lot) => {
                                        //         const mold = lot.data.moldId
                                        //         this.props.network.getMold(undefined, mold, true).then((mold) => {
                                        //             this.setState({ mold })
                                        //         })
                                        //     })
                                        // }
                                        this.setState({ machine })
                                    }}
                                />
                            </View>
                        ),
                        2: (
                            <View column fullh style={{ height: '95%' }}>
                                <View row between>
                                    <View column fullw around>
                                        <Numpad
                                            value={workerHours}
                                            type="number"
                                            control={Input}
                                            label={<span className="label">Ore operaio</span>}
                                            placeholder="Ore operaio"
                                            onChange={(value) => {
                                                this.setState({ workerHours: value })
                                            }}
                                        />
                                        <View around>
                                            <Switch
                                                onChange={(e) => {
                                                    this.setState({ workerMinutes: e.target.checked ? 30 : 0 })
                                                }}
                                            >
                                                Mezz{"'"}ora
                                            </Switch>
                                        </View>
                                    </View>
                                    {machine.machineId !== 1 && (
                                        <View column around fullw>
                                            <Numpad
                                                value={machineHours}
                                                type="number"
                                                control={Input}
                                                label={<span className="label">Ore macchina</span>}
                                                placeholder="Ore macchina"
                                                onChange={(value) => {
                                                    this.setState({ machineHours: value })
                                                }}
                                            />
                                            <View around>
                                                <Switch
                                                    label={<span className="label"></span>}
                                                    onChange={(e) => {
                                                        this.setState({ machineMinutes: e.target.checked ? 30 : 0 })
                                                    }}
                                                >
                                                    Mezz{"'"}ora
                                                </Switch>
                                            </View>
                                        </View>
                                    )}
                                </View>

                                <View row fullw fullh style={{ overflowY: 'scroll' }}>
                                    <Form style={{ margin: 'auto', marginBottom: 42, width: '100%', maxWidth: 1080 }}>
                                        <Form.Group widths="equal">
                                            <Form.Field
                                                id="form-input-control-work-bad-reason"
                                                control={Select}
                                                search
                                                options={[
                                                    {
                                                        key: 'AT1',
                                                        text: 'AT1 - Produzione',
                                                        value: 'AT1',
                                                    },
                                                    {
                                                        key: 'AT2',
                                                        text: 'AT2 - Attrezzaggio, Avviamento',
                                                        value: 'AT2',
                                                    },
                                                    {
                                                        key: 'AT3',
                                                        text: 'AT3 - Cambio colore',
                                                        value: 'AT3',
                                                    },
                                                    {
                                                        key: 'AT4',
                                                        text: 'AT4 - Manutenzione Ordinaria, Varie',
                                                        value: 'AT4',
                                                    },
                                                ]}
                                                label="Attività"
                                                placeholder="Attività"
                                                name="attivita"
                                                value={attivita}
                                                onChange={(e, data) => {
                                                    let attivita = data.value

                                                    console.log('Setting attivita to', attivita)
                                                    this.setState({ attivita })
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group widths="equal">
                                            <Form.Field
                                                id="form-input-control-work-description"
                                                label={<span className="label">Descrizione</span>}
                                                placeholder="Fresature"
                                                name="description"
                                                value={description}
                                                control={Input}
                                                onChange={(event) => {
                                                    const target = event.target
                                                    const value = target.type === 'checkbox' ? target.checked : target.value
                                                    const name = target.name

                                                    this.setState({
                                                        [name]: value,
                                                    })
                                                }}
                                            />
                                        </Form.Group>
                                        <>
                                            <Form.Group widths="equal">
                                                <Form.Field
                                                    id="form-input-control-work-adasd-iniziale"
                                                    control={Input}
                                                    label={<span className="label">Data inizio lavorazione</span>}
                                                    placeholder="1000"
                                                    name="timePickerValue"
                                                    value={timePickerValue}
                                                    onChange={(event) => {
                                                        const target = event.target
                                                        const value = target.type === 'checkbox' ? target.checked : target.value
                                                        const name = target.name

                                                        // Value is in the form 2023-06-20T12:15:10, parse it to a date and the time separately
                                                        const date = new Date(value)
                                                        const time = date.toLocaleTimeString('it-IT')
                                                        // Format to YYYY-MM-DD string
                                                        const dateOnly = moment(date).format('YYYY-MM-DD')

                                                        this.setState({
                                                            workStartDate: date,
                                                            workStartTime: time,
                                                            timePickerValue: value,
                                                        })
                                                    }}
                                                    type="datetime-local"
                                                    step="1"
                                                />
                                            </Form.Group>
                                            <Form.Group widths="equal">
                                                <Form.Field
                                                    id="form-input-control-work-contacicli-iniziale"
                                                    control={Input}
                                                    label={<span className="label">Contacicli iniziale</span>}
                                                    placeholder="1000"
                                                    name="startCycles"
                                                    value={startCycles}
                                                    onChange={(event) => {
                                                        const target = event.target
                                                        const value = target.type === 'checkbox' ? target.checked : target.value
                                                        const name = target.name

                                                        this.setState({
                                                            [name]: value,
                                                        })
                                                    }}
                                                    type="number"
                                                    step="1"
                                                />
                                                <Form.Field
                                                    id="form-input-control-work-contacicli-finale"
                                                    control={Input}
                                                    label={<span className="label">Contacicli finale</span>}
                                                    placeholder="1000"
                                                    name="endCycles"
                                                    value={endCycles}
                                                    onChange={(event) => {
                                                        const target = event.target
                                                        const value = target.type === 'checkbox' ? target.checked : target.value
                                                        const name = target.name

                                                        this.setState({
                                                            [name]: value,
                                                        })
                                                    }}
                                                    type="number"
                                                    step="1"
                                                />
                                            </Form.Group>
                                            <Form.Group widths="equal">
                                                <Form.Field
                                                    id="form-input-control-work-contacicli-iniziale"
                                                    control={Input}
                                                    label={<span className="label">Scarti</span>}
                                                    placeholder="0"
                                                    name="badPieces"
                                                    value={badPieces}
                                                    onChange={(event) => {
                                                        const target = event.target
                                                        const value = target.type === 'checkbox' ? target.checked : target.value
                                                        const name = target.name

                                                        this.setState({
                                                            [name]: value,
                                                        })
                                                    }}
                                                    type="number"
                                                    step="1"
                                                />
                                                <Form.Field
                                                    id="form-input-control-work-bad-reason"
                                                    control={Select}
                                                    search
                                                    options={[
                                                        {
                                                            key: 'NC1',
                                                            text: 'NC1',
                                                            value: 'NC1',
                                                        },
                                                        {
                                                            key: 'NC2',
                                                            text: 'NC2',
                                                            value: 'NC2',
                                                        },
                                                        {
                                                            key: 'NC3',
                                                            text: 'NC3',
                                                            value: 'NC3',
                                                        },
                                                        {
                                                            key: 'NC4',
                                                            text: 'NC4',
                                                            value: 'NC4',
                                                        },
                                                        {
                                                            key: 'NC5',
                                                            text: 'NC5',
                                                            value: 'NC5',
                                                        },
                                                        {
                                                            key: 'NC6',
                                                            text: 'NC6',
                                                            value: 'NC6',
                                                        },
                                                        {
                                                            key: 'NC7',
                                                            text: 'NC7',
                                                            value: 'NC7',
                                                        },
                                                        {
                                                            key: 'NC8',
                                                            text: 'NC8',
                                                            value: 'NC8',
                                                        },
                                                        {
                                                            key: 'NC9',
                                                            text: 'NC9',
                                                            value: 'NC9',
                                                        },
                                                    ]}
                                                    label="Causale scarti"
                                                    placeholder="Causale scarti"
                                                    name="badReason"
                                                    value={badReason}
                                                    onChange={(e, data) => {
                                                        let badReason = data.value

                                                        console.log('Setting badReason to', badReason)
                                                        this.setState({ badReason })
                                                    }}
                                                />
                                            </Form.Group>
                                        </>
                                    </Form>
                                </View>
                            </View>
                        ),
                    }[step]
                }
                <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                    <Button onClick={() => this.prev()}>Indietro</Button>
                    {step < 2 && (
                        <Button
                            color="black"
                            onClick={() => this.next()}
                            disabled={
                                (step === 0 && (!this.state.workOrders || Object.keys(this.state.workOrders).length === 0)) ||
                                (step === 1 && !machine.machineId)
                            }
                        >
                            Avanti
                        </Button>
                    )}
                    {step === 2 && (
                        <Button color="green" onClick={() => this.save()}>
                            Salva
                        </Button>
                    )}
                </View>
            </View>,
        ]

        return (
            <View fullw fullh column style={{ padding: 16 }}>
                {content}
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getDetailedJobList,
        createWork: { data: createResult, fetching: creatingWork, status: createStatus },
        workday,
    } = state

    return {
        getDetailedJobList,
        createResult,
        creatingWork,
        createStatus,
        workday,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Steps))
