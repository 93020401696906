export function getWorkOrderList(search, filters, sorts, history, isEmployee, page, items) {
    return new Promise((resolve, reject) => {
        let { getWorkOrderListRequest, getWorkOrderListError, getWorkOrderListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_workorder_list',
            this.createBundle(
                'get_workorder_list',
                { search, filters, sorts, history, page, items },
                this.POST,
                getWorkOrderListRequest,
                (result) => {
                    getWorkOrderListResult(result)
                    resolve(result)
                },
                (error) => {
                    getWorkOrderListError(error)
                    reject(error)
                },
                isEmployee
            )
        )
    })
}
