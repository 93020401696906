import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'
class Empty extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let { type } = this.props

        switch (type) {
            case 'small':
                return (
                    <Box p={6} style={styles.small}>
                        <span style={styles.smallHeader}>Nessun elemento da visualizzare</span>
                    </Box>
                )
            case 'ultrasmall':
                return (
                    <Box p={6} style={{ padding: 4, margin: 0 }}>
                        <span style={styles.compactHeader}>Nessun elemento da visualizzare</span>
                    </Box>
                )
            case 'compact':
                return null
            default:
                return (
                    <Box p={6}>
                        <Box textStyle="header" icon>
                            <Icon name="dont" />
                            Nessun elemento da visualizzare
                        </Box>
                    </Box>
                )
        }
    }
}

const styles = {
    small: {
        minHeight: 60,
    },
    compact: {
        minHeight: 30,
    },
    compactHeader: {
        marginBottom: 0,
        fontSize: '11pt',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    smallHeader: {
        marginBottom: 0,
        fontSize: '18pt',
        fontWeight: 'bold',
        textAlign: 'center',
    },
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

export default connect(mapStateToProps)(connectNetwork(Empty))
