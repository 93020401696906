import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Step, Table } from 'semantic-ui-react'
import { Articles } from '../containers'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from '../lib/View'
import Material from './Material'
import Mold from './Mold'
import Molds from './Molds'
import Package from './Package'
function KrilaMatCheck(props) {
    const { network, toolbarResult, filtersResult, sortsResult, isEmployee } = props
    const [scannedMaterials, setScannedMaterials] = useState([])
    const [scannedPackages, setScannedPackages] = useState([])
    const [scannedCode, setScannedCode] = useState('')
    const [selectedArticle, setSelectedArticle] = useState(null)
    const [selectedMold, setSelectedMold] = useState(null)
    const [step, setStep] = useState(0)

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    useEffect(() => {
        setImmediate(() => {
            const scannedCodeEl = document.getElementById('form-input-control-article-code')
            if (scannedCodeEl) {
                scannedCodeEl.focus()
                scannedCodeEl.onblur = (event) => {
                    setTimeout(() => {
                        var blurEl = document.getElementById('form-input-control-article-code')
                        if (blurEl) {
                            blurEl.focus()
                        }
                    }, 10)
                }
            }
        })
    }, [step])

    if (step === 2) {
        if (selectedArticle && selectedArticle.materials) {
            for (const material of selectedArticle.materials) {
                if (material.materialCode === scannedCode) {
                    setScannedCode('')
                    setScannedMaterials([...scannedMaterials, material.moldCode])
                }
            }
            if (scannedMaterials.length === selectedArticle.materials.length) {
                setScannedCode('')
                setStep(3)
            }
        } else {
            setScannedCode('')
            setStep(3)
        }
    }

    if (step === 3) {
        if (selectedArticle && selectedArticle.packages) {
            for (const pack of selectedArticle.packages) {
                if (pack.packageCode === scannedCode) {
                    setScannedCode('')
                    setScannedPackages([...scannedPackages, pack.moldCode])
                }
            }
            if (scannedPackages.length === selectedArticle.packages.length) {
                setScannedCode('')
                setStep(4)
            }
        } else {
            setScannedCode('')
            setStep(4)
        }
    }

    return (
        <>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-article-code"
                        control={Input}
                        label={<span className="label">Scansione</span>}
                        name="scannedCode"
                        value={scannedCode}
                        onChange={(e) => {
                            setScannedCode(e.target.value)
                        }}
                        placeholder="SCANSIONA QUI"
                        style={{
                            fontSize: 64,
                        }}
                    />
                </Form.Group>
            </Form>
            <Step.Group style={{ width: '100%' }}>
                <Step active={step === 0}>
                    <Icon name="file alternate outline" />
                    <Step.Content>
                        <Step.Title>Stampo</Step.Title>
                        <Step.Description>Scansiona lo stampo da caricare in macchina</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 1}>
                    <Icon name="tablet alternate" />
                    <Step.Content>
                        <Step.Title>Articolo</Step.Title>
                        <Step.Description>Seleziona uno degli articoli dello stampo</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 2}>
                    <Icon name="cube" />
                    <Step.Content>
                        <Step.Title>Materiale</Step.Title>
                        <Step.Description>Scansiona i materiali corretti</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 3}>
                    <Icon name="cube" />
                    <Step.Content>
                        <Step.Title>Imballo</Step.Title>
                        <Step.Description>Scansiona gli imballi corretti</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 4}>
                    <Icon name="check" />
                    <Step.Content>
                        <Step.Title>Fine</Step.Title>
                        <Step.Description>Procedura completa</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
            <Table celled>
                {step === 0 && (
                    <>
                        <Molds
                            isEmployee={isEmployee}
                            noActions
                            selectable
                            scannedCode={scannedCode}
                            sub
                            onRowClick={(mold) => {
                                /* eslint-disable no-console */
                                console.log('onRowClick', mold)
                                setSelectedMold(mold)
                                setScannedCode('')
                                setStep(1)
                            }}
                        />
                    </>
                )}
                {step === 1 && (
                    <>
                        <Articles
                            isEmployee={isEmployee}
                            noActions
                            selectable
                            onChange={(article) => {
                                // console.log('Selected article', article)
                                setSelectedArticle(article)
                                setScannedCode('')
                                setStep(2)
                            }}
                            sub
                            filters={{
                                articleMold: {
                                    value: [{ moldCode: selectedMold.moldCode }],
                                    key: 'articleMold',
                                    operator: '=',
                                },
                            }}
                        />
                    </>
                )}
                {step === 2 && (
                    <>
                        <Material type="header" hideActions hideBarcode />
                        <Table.Body>
                            {selectedArticle && selectedArticle.materials
                                ? selectedArticle.materials.map((value, index) => {
                                      return (
                                          <Material
                                              key={value.materialId}
                                              type="table"
                                              material={value}
                                              hideActions
                                              hideBarcode
                                              isEmployee={isEmployee}
                                              scannedMaterials={scannedMaterials}
                                          />
                                      )
                                  })
                                : null}
                        </Table.Body>
                    </>
                )}
                {step === 3 && (
                    <>
                        <Material type="header" hideActions hideBarcode />
                        <Table.Body>
                            {selectedArticle && selectedArticle.packages
                                ? selectedArticle.packages.map((value, index) => {
                                      return (
                                          <Package
                                              key={value.packageId}
                                              type="table"
                                              pack={value}
                                              hideActions
                                              hideBarcode
                                              isEmployee={isEmployee}
                                              scannedPackages={scannedPackages}
                                          />
                                      )
                                  })
                                : null}
                        </Table.Body>
                    </>
                )}
            </Table>
            <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                {step > 0 && (
                    <Button
                        onClick={() => {
                            setStep(step - 1)
                            setScannedMaterials([])
                        }}
                    >
                        Indietro
                    </Button>
                )}
                {step === 4 && (
                    <Button
                        color="green"
                        onClick={() => {
                            // console.log('Sending to machine...')
                            setStep(0)
                            setScannedMaterials([])
                        }}
                    >
                        Fine
                    </Button>
                )}
            </View>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteMold, mergeMold } = state

    return {
        getMachineList,
        deleteMold,
        mergeMold,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(KrilaMatCheck)))
