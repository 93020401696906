import { Box, Spinner } from '@chakra-ui/react'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Input, Modal, Table } from 'semantic-ui-react'
import WorkOrderEditor from '../job/WorkOrderEditor'
import { baseViewDispatch, initializePage } from '../lib/util'
import WorkOrder from './WorkOrder'
import { Center, Square, Circle } from '@chakra-ui/react'
import ReactDatePicker from 'react-datepicker'

function WorkOrders(props) {
    const {
        network,
        table,
        time,
        label,
        field,
        format,
        toolbarResult,
        filtersResult,
        sortsResult,
        createWorkOrder,
        deleteWorkOrder,
        mergeWorkOrder,
        searchText,
        sub,
        selectable,
        onChangeSelection,
        defaultSelectedWorkOrders,
        noActions,
        showHistory,
        fixedWorkOrders,
        isEmployee,
        search,
        searchOrderId,
    } = props
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        workOrder: null,
    })

    const [dirtySelection, setDirtySelection] = useState(false)
    const [workOrders, setWorkOrders] = useState([])
    const [selectedWorkOrders, setSelectedWorkOrders] = useState(defaultSelectedWorkOrders ? defaultSelectedWorkOrders : {})
    const [dirty, setDirty] = useState(false)
    const [editing, setEditing] = useState(false)
    const [creating, setCreating] = useState(false)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [filters, setFilters] = useState(props.filters ? props.filters : {})
    const [sorts, setSorts] = useState(props.sorts ? props.sorts : {})

    useEffect(() => {
        setLoading(true)
        if (fixedWorkOrders) {
            setWorkOrders(fixedWorkOrders)
            setDirty(false)
            setLoading(false)
            setPage(-1)
        } else {
            let finalFilters = filters
            if (searchOrderId) {
                finalFilters = {
                    ...filters,
                    workOrderId: {
                        value: searchOrderId,
                        key: 'workOrderId',
                        operator: '=',
                    },
                }
            }

            network
                .getWorkOrderList(search ? search : searchText ? searchText : '', finalFilters, sorts, showHistory, !!isEmployee, page, 10)
                .then((data) => {
                    const workOrders = []
                    for (const key in data.data) {
                        workOrders.push(data.data[key])
                    }
                    setWorkOrders(workOrders)
                    setDirty(false)
                    setLoading(false)
                })
        }
    }, [dirty, deleteWorkOrder.fetching, createWorkOrder.fetching, search, searchText, showHistory, fixedWorkOrders, searchOrderId, page]) // mergeWorkOrder.fetching

    useEffect(() => {
        let pageItems = [
            {
                name: `${parseInt(page) + 1}`,
                position: 'none',
                type: 'text',
            },
        ]

        let toolbar = sub
            ? []
            : [
                  {
                      name: '',
                      icon: 'arrow left',
                      position: 'right',
                      action: () => {
                          if (page >= 0) {
                              setPage(page - 1)
                          }
                      },
                  },
                  ...pageItems,
                  {
                      name: '',
                      icon: 'arrow right',
                      position: 'none',
                      action: () => {
                          setPage(page + 1)
                      },
                  },
              ]

        if (!showHistory) {
            toolbar = [
                {
                    name: 'Crea riga ordine',
                    icon: 'plus',
                    position: 'left',
                    action: () => {
                        setCreating(true)
                    },
                },
                ...toolbar,
            ]
        }

        const onChange = (obj, value) => {
            sorts[obj.name] = value
            setSorts(sorts)
            setWorkOrders([])
            setDirty(!dirty)
        }

        const onReset = (obj) => {
            delete sorts[obj.name]
            setSorts(sorts)
            setWorkOrders([])
            setDirty(!dirty)
        }

        const sortoptions = sub
            ? {}
            : {
                  workOrderId: {
                      name: 'workOrderId',
                      label: 'Ordine interno',
                      onChange,
                      onReset,
                  },
                  jobCode: {
                      name: 'jobCode',
                      label: 'Ordine cliente',
                      onChange,
                      onReset,
                  },
                  customerName: {
                      name: 'customerName',
                      label: 'Cliente',
                      onChange,
                      onReset,
                  },
                  articleCode: {
                      name: 'workCode',
                      label: 'Codice articolo',
                      onChange,
                      onReset,
                  },
                  articleName: {
                      name: 'articleName',
                      label: 'Descrizione articolo',
                      onChange,
                      onReset,
                  },
                  productQuantity: {
                      name: 'productQuantity',
                      label: 'Qta da produrre',
                      onChange,
                      onReset,
                  },
                  producedQuantity: {
                      name: 'producedQuantity',
                      label: 'Qta prodotta',
                      onChange,
                      onReset,
                  },
                  stampedQuantity: {
                      name: 'stampedQuantity',
                      label: 'Qta bollata',
                      onChange,
                      onReset,
                  },
                  deliveredQuantity: {
                      name: 'deliveredQuantity',
                      label: 'Qta consegnata',
                      onChange,
                      onReset,
                  },
                  remainingQuantity: {
                      name: 'remainingQuantity',
                      label: 'Qta rimanente',
                      onChange,
                      onReset,
                  },
                  deliveryDate: {
                      name: 'deliveryDate',
                      label: 'Data di consegna',
                      onChange,
                      onReset,
                  },
                  productionDate: {
                      name: 'productionDate',
                      label: 'Data prod. prev.',
                      onChange,
                      onReset,
                  },
                  creationDate: {
                      name: 'creationDate',
                      label: 'Data di creazione',
                      onChange,
                      onReset,
                  },
              }

        const filterChangeGenerator = (name, type = 'text', operator = '=') => {
            return (e) => {
                let value = ''
                if (type === 'date') {
                    value = moment(e).format('YYYY-MM-DD')
                } else {
                    value = operator === 'LIKE' ? `%${e.target.value}%` : e.target.value
                }

                filters[name] = {
                    value,
                    key: name,
                    operator: operator,
                }
                setFilters(filters)
                setWorkOrders([])
                setDirty(!dirty)
            }
        }

        const filterResetGenerator = (name) => {
            return (e) => {
                delete filters[name]
                setFilters(filters)
                setWorkOrders([])
                setDirty(!dirty)
            }
        }

        const filteroptions = sub
            ? {}
            : {
                  articleCode: {
                      control: Input,
                      name: 'articleCode',
                      input: 'text',
                      placeholder: 'ART153',
                      label: 'Codice articolo',
                      onChange: filterChangeGenerator('articleCode', 'text', 'LIKE'),
                      onReset: filterResetGenerator('articleCode'),
                  },
                  articleName: {
                      control: Input,
                      name: 'articleName',
                      input: 'text',
                      placeholder: 'Parte alta',
                      label: 'Descrizione articolo',
                      onChange: filterChangeGenerator('articleName', 'text', 'LIKE'),
                      onReset: filterResetGenerator('articleName'),
                  },
                  jobCode: {
                      control: Input,
                      input: 'text',
                      name: 'jobCode',
                      label: 'Ordine cliente',
                      onChange: filterChangeGenerator('jobCode', 'text', 'LIKE'),
                      onReset: filterResetGenerator('jobCode'),
                  },
                  customerName: {
                      control: Input,
                      input: 'text',
                      name: 'customerName',
                      label: 'Cliente',
                      onChange: filterChangeGenerator('customerName', 'text', 'LIKE'),
                      onReset: filterResetGenerator('customerName'),
                  },
                  productQuantity: {
                      control: Input,
                      input: 'number',
                      name: 'productQuantity',
                      label: 'Qta da produrre',
                      onChange: filterChangeGenerator('productQuantity', 'number'),
                      onReset: filterResetGenerator('productQuantity'),
                  },
                  producedQuantity: {
                      control: Input,
                      input: 'number',
                      name: 'producedQuantity',
                      label: 'Qta prodotta',
                      onChange: filterChangeGenerator('producedQuantity', 'number'),
                      onReset: filterResetGenerator('producedQuantity'),
                  },
                  stampedQuantity: {
                      control: Input,
                      input: 'number',
                      name: 'stampedQuantity',
                      label: 'Qta bollata',
                      onChange: filterChangeGenerator('stampedQuantity', 'number'),
                      onReset: filterResetGenerator('stampedQuantity'),
                  },
                  deliveredQuantity: {
                      control: Input,
                      input: 'number',
                      name: 'deliveredQuantity',
                      label: 'Qta consegnata',
                      onChange: filterChangeGenerator('deliveredQuantity', 'number'),
                      onReset: filterResetGenerator('deliveredQuantity'),
                  },
                  remainingQuantity: {
                      control: Input,
                      input: 'number',
                      name: 'remainingQuantity',
                      label: 'Qta rimanente',
                      onChange: filterChangeGenerator('remainingQuantity', 'number'),
                      onReset: filterResetGenerator('remainingQuantity'),
                  },
                  deliveryDateStart: {
                      control: Input,
                      input: 'text',
                      name: 'deliveryDate',
                      label: 'Data di consegna (inizio)',
                      onChange: filterChangeGenerator('deliveryDateStart', 'date', '>='),
                      onReset: filterResetGenerator('deliveryDateStart'),
                  },
                  creationDateStart: {
                      control: Input,
                      input: 'text',
                      name: 'creationDate',
                      label: 'Data di creazione (inizio)',
                      onChange: filterChangeGenerator('creationDateStart', 'date', '>='),
                      onReset: filterResetGenerator('creationDateStart'),
                  },
                  deliveryDateEnd: {
                      control: Input,
                      input: 'text',
                      name: 'deliveryDate',
                      label: 'Data di consegna (fine)',
                      onChange: filterChangeGenerator('deliveryDateEnd', 'date', '<='),
                      onReset: filterResetGenerator('deliveryDateEnd'),
                  },
                  creationDateEnd: {
                      control: Input,
                      input: 'text',
                      name: 'creationDate',
                      label: 'Data di creazione (fine)',
                      onChange: filterChangeGenerator('creationDateEnd', 'date', '<='),
                      onReset: filterResetGenerator('creationDateEnd'),
                  },
                  workOrderId: {
                      control: Input,
                      name: 'workOrderId',
                      input: 'text',
                      placeholder: '122',
                      label: 'Codice ordine di lavoro interno',
                      onChange: filterChangeGenerator('workOrderId'),
                      onReset: filterResetGenerator('workOrderId'),
                  },
              }

        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, toolbar, filteroptions, sortoptions)
    }, [page])

    if (loading) {
        return (
            <Box w="100%" h="300px" mt="32px">
                <Center>
                    <Spinner size="xl" />
                </Center>
            </Box>
        )
    }

    return (
        <>
            {workOrders && workOrders.length > 0 ? (
                <Table celled sortable>
                    <WorkOrder
                        {...props}
                        type="header"
                        sorts={sorts}
                        selectable={!!selectable}
                        onHeaderClick={(name) => {
                            if (!sorts[name]) {
                                sorts[name] = 'asc'
                            } else if (sorts[name] === 'asc') {
                                sorts[name] = 'desc'
                            } else {
                                delete sorts[name]
                            }
                            setSorts(sorts)
                            setWorkOrders([])
                            setDirty(!dirty)
                        }}
                        hideBarcode
                        noWarehouse
                    />
                    <Table.Body>
                        {workOrders.map((value, index) => {
                            return (
                                <WorkOrder
                                    {...props}
                                    key={value.workOrderId}
                                    type="table"
                                    workOrder={value}
                                    onRowClick={(state) => {
                                        const { workOrderId } = state
                                        setEditing(workOrderId)
                                    }}
                                    selectable={!!selectable}
                                    selected={!!selectedWorkOrders[value.workOrderId]}
                                    onSelected={
                                        selectable
                                            ? (workOrderId, checked, object) => {
                                                  selectedWorkOrders[workOrderId] = checked
                                                  setSelectedWorkOrders(selectedWorkOrders)
                                                  if (onChangeSelection) {
                                                      onChangeSelection(selectedWorkOrders, workOrderId, object, checked)
                                                  }
                                                  setDirtySelection(!dirtySelection)
                                              }
                                            : () => {}
                                    }
                                    noActions={!!noActions}
                                    hideBarcode
                                    noWarehouse
                                />
                            )
                        })}
                    </Table.Body>
                </Table>
            ) : null}

            <Modal
                closeIcon
                open={!!editing || !!creating}
                onClose={() => {
                    setEditing(false)
                    setCreating(false)
                }}
            >
                <Modal.Header>{creating ? 'Creando' : 'Modificando'} riga ordine cliente</Modal.Header>
                <Modal.Content>
                    <WorkOrderEditor
                        workOrderId={editing}
                        onSave={(workOrder) => {
                            setEditing(false)
                            setCreating(false)
                            setWorkOrders([])
                            setDirty(!dirty)
                        }}
                        onCancel={() => {
                            setEditing(false)
                            setCreating(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let {
        createWorkOrder,
        getMachineList,
        deleteWorkOrder,
        mergeWorkOrder,
        search: { data: searchText },
    } = state

    return {
        createWorkOrder,
        getMachineList,
        deleteWorkOrder,
        mergeWorkOrder,
        searchText,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(WorkOrders)))
