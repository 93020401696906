export function createDocument(head, body) {
    let { createDocumentRequest, createDocumentError, createDocumentResult } = this.props
    return new Promise((resolve, reject) => {
        // delete head.creationDate
        // delete head.updateDate

        this.doAction(
            this.WEB_REQUEST_URL + 'app/document/save',
            this.createBundle(
                'app/document/save',
                { documentHead: head, documentBody: body },
                this.POST,
                createDocumentRequest,
                (result) => {
                    createDocumentResult(result)
                    resolve(result)
                },
                (error) => {
                    createDocumentError(error)
                    reject(error)
                }
            )
        )
    })
}
