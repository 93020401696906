import {} from '../containers'
import { connectNetwork } from '../lib/NetworkProvider'
import { routes, route_from } from '../lib/routes'
import View from '../lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { DropTarget } from 'react-dnd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Card, Icon, Input, Table } from 'semantic-ui-react'
import DraggableBarcode from '../labeling/DraggableBarcode'
import DraggableBox from '../labeling/DraggableBox'
import DraggableImage from '../labeling/DraggableImage'
import DraggableText from '../labeling/DraggableText'
import { floatTimeToString, timeString } from '../lib/util'
import { Confirmation, ImageDefault } from './'

const { app, labels, labelEdit } = routes

const LABEL_PADDING = 16

class Label extends Component {
    constructor(props) {
        super(props)

        this.componentContainerRef = null

        this.setComponentContainerRef = (element) => {
            this.componentContainerRef = element
        }

        let { onCostChange, label } = props

        this.state = {
            deleting: false,
            ...label,
            labelMinutes: label ? parseFloat(label.labelMinutes) : 0,
        }

        if (onCostChange && label) {
            onCostChange(this.totalCost(), label.id)
        }
    }

    totalCost() {
        let { labelHourlyRate, labelHours, labelMinutes } = this.state
        let total = (labelHours + labelMinutes / 60) * parseFloat(labelHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { label, id, network } = this.props

        if ((!label || !label.id) && id) {
            network.getLabel(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getLabel, login } = this.props
        let { id, labelHourlyRate } = this.state
        if (labelHourlyRate !== prevState.labelHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), id)
            }
        }

        //console.log('Label getLabel fetching', getLabel.fetching)
        if (getLabel && getLabel.fetching !== prevProps.getLabel.fetching && !getLabel.fetching) {
            //console.log('Done calling getLabel')
            if (getLabel.status.success) {
                //console.log(getLabel.data)
                let label = getLabel.data.data

                this.setState({ ...label })
                //console.log('Calling Get label', login, label)
                return
            } else {
                //console.log('calling No label found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { name, labelHourlyRate, labelHours, labelMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={labelHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    labelHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{labelHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(labelHours, labelMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideLabel, hideLabelHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideLabel && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideLabelHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideLabel, hideLabelHours, isBalance } = this.props
        let { name, labelHours, labelMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideLabel && <Table.Cell>{name}</Table.Cell>}
                    {!hideLabelHours && <Table.Cell>{timeString(labelHours, labelMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, label } = this.props

        network.deleteLabel(label)

        this.setState({ deleting: false })
    }

    addLayoutElement = (value, style) => {
        const { layout } = this.state

        value.layoutElementStyle = JSON.stringify(style)
        delete value.isNewElement
        layout.elements.push(value)

        this.setState({ layout })
    }

    renderLayoutElement(value, index) {
        const { label, selectedArti, selectedCustomer, selectedPackage, worker } = this.props
        const { layoutElementStyle: style } = value
        const { isPreview } = this.props

        const { pack, customer, article, layout } = label

        const extraProps = {}
        if (isPreview) {
            extraProps.isPreview = isPreview
        }

        let layoutElementStyle = {}
        if (style) {
            const sanitized = style.replace(/\\n/g, '').replace(/\\r/g, '').replace(/\\/g, '')
            layoutElementStyle = JSON.parse(sanitized)
        }

        switch (value.layoutElementType) {
            default:
            case 1: // Text
                return (
                    <DraggableText
                        {...extraProps}
                        key={index}
                        layoutElementStyle={layoutElementStyle}
                        value={value}
                        // pack={pack}
                        customer={selectedCustomer}
                        article={article}
                        layout={layout}
                        arti={selectedArti}
                        worker={worker}
                        pack={selectedPackage}
                        currentSession={this.props.currentSession}
                        padding={LABEL_PADDING}
                    />
                )
            case 2: // Image
                return (
                    <DraggableImage
                        {...extraProps}
                        key={index}
                        layoutElementStyle={layoutElementStyle}
                        value={value}
                        // pack={pack}
                        customer={selectedCustomer}
                        article={article}
                        layout={layout}
                        arti={selectedArti}
                        worker={worker}
                        pack={selectedPackage}
                        currentSession={this.props.currentSession}
                        padding={LABEL_PADDING}
                    />
                )
            case 3: // Barcode
                return (
                    <DraggableBarcode
                        {...extraProps}
                        index={index}
                        layoutElementStyle={layoutElementStyle}
                        value={value}
                        // pack={pack}
                        customer={selectedCustomer}
                        article={article}
                        layout={layout}
                        arti={selectedArti}
                        worker={worker}
                        pack={selectedPackage}
                        currentSession={this.props.currentSession}
                        padding={LABEL_PADDING}
                    />
                )
            case 4: // Box
                return (
                    <DraggableBox
                        {...extraProps}
                        key={index}
                        layoutElementStyle={layoutElementStyle}
                        value={value}
                        // pack={pack}
                        customer={selectedCustomer}
                        article={article}
                        layout={layout}
                        arti={selectedArti}
                        worker={worker}
                        pack={selectedPackage}
                        currentSession={this.props.currentSession}
                        padding={LABEL_PADDING}
                    />
                )
        }
    }

    render() {
        let { hideActions, login, type, label, onClick, selected, noActions, traversable, connectDropTarget } = this.props
        let { deleting, labelId: id, labelName, labelDescription, labelImages, layout } = this.state

        if (type === 'visualization') {
            //console.log('Label is', id)
            //console.log('Label layout is', layout)
        }

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(label)
            }
        }
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }
        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            case 'compact':
                return (
                    <div ref={this.setComponentContainerRef}>
                        <Card style={{ height: 200, width: 200 }}>
                            <Card.Header>Label compact {labelName}</Card.Header>
                        </Card>
                    </div>
                )
            case 'edit':
                if (!layout) return null
                return connectDropTarget(
                    <div style={{ backgroundColor: '#F0F0F0', width: layout.layoutWidth, height: layout.layoutHeight }}>
                        {Object.keys(layout.elements).map((key, index) => {
                            let value = layout.elements[key]
                            return this.renderLayoutElement(value, index)
                        })}
                    </div>
                )
            case 'visualization':
                if (!layout) return null
                return (
                    <View fullw fullh>
                        <div style={{ width: layout.layoutWidth, height: layout.layoutHeight }}>
                            {Object.keys(layout.elements).map((key, index) => {
                                let value = layout.elements[key]
                                return this.renderLayoutElement(value, index)
                            })}
                        </div>
                    </View>
                )
            default:
                return (
                    <View fullw column between>
                        <Card className={selected ? 'rowSelected' : ''} style={styles.labelCard} {...extraprops}>
                            <Confirmation
                                message="Vuoi davvero eliminare questa collezione?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Card.Content style={styles.labelContent}>
                                <View fullw row>
                                    {labelImages && labelImages.length > 0 && (
                                        <div style={styles.imageContainer}>
                                            <ImageDefault fromRoot src={labelImages[0].picturePath} ui={false} style={styles.labelImage} />
                                        </div>
                                    )}
                                    {!labelImages ||
                                        (labelImages.length === 0 && (
                                            <div style={styles.imageContainer}>
                                                <ImageDefault fromRoot src={null} ui={false} style={styles.labelImage} />
                                            </div>
                                        ))}
                                    <View fullw column>
                                        <span style={styles.header}>{labelName}</span>
                                        <Card.Description>{labelDescription}</Card.Description>
                                    </View>
                                </View>
                            </Card.Content>
                            {!noActions && (
                                <Card.Content extra>
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.props.history.push(route_from(app, labels, labelEdit, `${id}`))
                                        }}
                                    >
                                        <Icon name="edit" />
                                        Modifica
                                    </Button>
                                </Card.Content>
                            )}
                        </Card>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 200,
        height: 150,
        marginRight: 28,
    },
    labelImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    labelContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    labelCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getLabel } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getLabel, login }
}

const spec = {
    drop(props, monitor, component) {
        const item = monitor.getItem()
        const delta = monitor.getDifferenceFromInitialOffset()

        let curLeft = item.data.layoutElementX
        let curTop = item.data.layoutElementY

        const layoutElementX = Math.round(curLeft + delta.x)
        const layoutElementY = Math.round(curTop + delta.y)

        item.data.layoutElementX = layoutElementX
        item.data.layoutElementY = layoutElementY

        //console.log(component)
        //console.log(item.component)
        if (item.data.isNewElement) {
            item.data.layoutElementX = 0
            item.data.layoutElementY = 0
            component.addLayoutElement(item.data, item.style)
        } else {
            item.component.forceUpdate()
        }
    },
}

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
        dropResult: monitor.getDropResult(),
    }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(DropTarget('any', spec, collect)(Label))))
