import { reducer as notifications } from 'react-notification-system-redux'
import { combineReducers } from 'redux'
import { LOGIN_TYPES } from '../actions/login'
import assignWork from './assignWork'
import changePassword from './changePassword'
import clearParent from './clearParent'
import clientOrderListState from './clientOrderListState'
import cloneTasks from './cloneTasks'
import createArticle from './createArticle'
import createArticleImport from './createArticleImport'
import createBadge from './createBadge'
import createBadgeScan from './createBadgeScan'
import createCategory from './createCategory'
import createClientOrder from './createClientOrder'
import createCollection from './createCollection'
import createCommand from './createCommand'
import createContract from './createContract'
import createCorp from './createCorp'
import createCost from './createCost'
import createCustomer from './createCustomer'
import createDeposit from './createDeposit'
import createDocument from './createDocument'
import createFamily from './createFamily'
import createInterval from './createInterval'
import createJob from './createJob'
import createKit from './createKit'
import createLot from './createLot'
import createMachine from './createMachine'
import createMachineType from './createMachineType'
import createMapping from './createMapping'
import createMaster from './createMaster'
import createMaterial from './createMaterial'
import createMold from './createMold'
import createOffice from './createOffice'
import createOrganization from './createOrganization'
import createPackage from './createPackage'
import createPicture from './createPicture'
import createProduct from './createProduct'
import createProduction from './createProduction'
import createSchedule from './createSchedule'
import createStdArticle from './createStdArticle'
import createTask from './createTask'
import createTimeCalendar from './createTimeCalendar'
import createTurn from './createTurn'
import createUser from './createUser'
import createVariant from './createVariant'
import createWork from './createWork'
import createWorker from './createWorker'
import createWorkOrder from './createWorkOrder'
import deleteArticle from './deleteArticle'
import deleteArticleVariant from './deleteArticleVariant'
import deleteBadge from './deleteBadge'
import deleteBadgeScan from './deleteBadgeScan'
import deleteBlackbox from './deleteBlackbox'
import deleteCategory from './deleteCategory'
import deleteClientOrder from './deleteClientOrder'
import deleteCollection from './deleteCollection'
import deleteCommand from './deleteCommand'
import deleteContract from './deleteContract'
import deleteCorp from './deleteCorp'
import deleteCost from './deleteCost'
import deleteCustomer from './deleteCustomer'
import deleteDeposit from './deleteDeposit'
import deleteDocument from './deleteDocument'
import deleteFamily from './deleteFamily'
import deleteInterval from './deleteInterval'
import deleteJob from './deleteJob'
import deleteKit from './deleteKit'
import deleteLot from './deleteLot'
import deleteMachine from './deleteMachine'
import deleteMachineType from './deleteMachineType'
import deleteMapping from './deleteMapping'
import deleteMaster from './deleteMaster'
import deleteMaterial from './deleteMaterial'
import deleteMold from './deleteMold'
import deleteOffice from './deleteOffice'
import deleteOrganization from './deleteOrganization'
import deletePackage from './deletePackage'
import deletePicture from './deletePicture'
import deleteProduct from './deleteProduct'
/* @reducers */
import getWorkOrderListSpan from './getWorkOrderListSpan' 
import deleteRecipe from './deleteRecipe' 
import createRecipe from './createRecipe' 
import getRecipeList from './getRecipeList' 
import getRecipe from './getRecipe' 
import getListingForWorker from './getListingForWorker' 
import deleteListing from './deleteListing' 
import createListing from './createListing' 
import getListingList from './getListingList' 
import getListing from './getListing' 
import deleteProduction from './deleteProduction'
import deleteSchedule from './deleteSchedule'
import deleteStdArticle from './deleteStdArticle'
import deleteTask from './deleteTask'
import deleteTimeCalendar from './deleteTimeCalendar'
import deleteTurn from './deleteTurn'
import deleteUser from './deleteUser'
import deleteVariant from './deleteVariant'
import deleteWork from './deleteWork'
import deleteWorker from './deleteWorker'
import deleteWorkList from './deleteWorkList'
import deleteWorkOrder from './deleteWorkOrder'
import doAssignDuplicates from './doAssignDuplicates'
import doAssignVariants from './doAssignVariants'
import filters from './filters'
import getArticle from './getArticle'
import getArticleList from './getArticleList'
import getArticleRecap from './getArticleRecap'
import getArticleVariantImageList from './getArticleVariantImageList'
import getBabypressaData from './getBabypressaData'
import getBabypressaDataFilter from './getBabypressaDataFilter'
import getBadge from './getBadge'
import getBadgeList from './getBadgeList'
import getBadgeScan from './getBadgeScan'
import getBadgeScanList from './getBadgeScanList'
import getBlackboxList from './getBlackboxList'
import getCategory from './getCategory'
import getCategoryList from './getCategoryList'
import getClientOrder from './getClientOrder'
import getClientOrderList from './getClientOrderList'
import getClientOrderTypeList from './getClientOrderTypeList'
import getCollection from './getCollection'
import getCollectionList from './getCollectionList'
import getCollectionsFull from './getCollectionsFull'
import getCommand from './getCommand'
import getCommandList from './getCommandList'
import getContract from './getContract'
import getContractList from './getContractList'
import getCorp from './getCorp'
import getCorpList from './getCorpList'
import getCost from './getCost'
import getCostList from './getCostList'
import getCustomer from './getCustomer'
import getCustomerList from './getCustomerList'
import getDataImportList from './getDataImportList'
import getDeposit from './getDeposit'
import getDepositList from './getDepositList'
import getDetailedClientOrderList from './getDetailedClientOrderList'
import getDetailedJobList from './getDetailedJobList'
import getDocument from './getDocument'
import getDocumentList from './getDocumentList'
import getDocumentRecap from './getDocumentRecap'
import getFamily from './getFamily'
import getFamilyList from './getFamilyList'
import getFromId from './getFromId'
import getFromIdFiltering from './getFromIdFiltering'
import getFromIdSubid from './getFromIdSubid'
import getFromIdSubidFiltering from './getFromIdSubidFiltering'
import getGT2000Data from './getGT2000Data'
import getGT2000DataFilter from './getGT2000DataFilter'
import getImageList from './getImageList'
import getInterval from './getInterval'
import getIntervalList from './getIntervalList'
import getItemCosts from './getItemCosts'
import getItemList from './getItemList'
import getJob from './getJob'
import getJobList from './getJobList'
import getJobTaskList from './getJobTaskList'
import getJobTypeList from './getJobTypeList'
import getKit from './getKit'
import getKitList from './getKitList'
import getLastSWA300Data from './getLastSWA300Data'
import getLot from './getLot'
import getLotList from './getLotList'
import getMachine from './getMachine'
import getMachineList from './getMachineList'
import getMachineTypeList from './getMachineTypeList'
import getMappingData from './getMappingData'
import getMaster from './getMaster'
import getMasterList from './getMasterList'
import getMaterial from './getMaterial'
import getMaterialList from './getMaterialList'
import getMold from './getMold'
import getMoldList from './getMoldList'
import getOffice from './getOffice'
import getOfficeList from './getOfficeList'
import getOrganization from './getOrganization'
import getOrganizationList from './getOrganizationList'
import getPackage from './getPackage'
import getPackageList from './getPackageList'
import getPagedCostList from './getPagedCostList'
import getPagedJobList from './getPagedJobList'
import getPicture from './getPicture'
import getPictureList from './getPictureList'
import getProduct from './getProduct'
import getProduction from './getProduction'
import getProductionList from './getProductionList'
import getProductList from './getProductList'
import getProductRecap from './getProductRecap'
import getRecentClientOrderList from './getRecentClientOrderList'
import getRecentJobList from './getRecentJobList'
import getRecentMachineList from './getRecentMachineList'
import getRoleList from './getRoleList'
import getSchedule from './getSchedule'
import getScheduleList from './getScheduleList'
import getSimpleArticleList from './getSimpleArticleList'
import getStdArticle from './getStdArticle'
import getStdArticleList from './getStdArticleList'
import getSWA300Data from './getSWA300Data'
import getTask from './getTask'
import getTaskList from './getTaskList'
import getTimeCalendar from './getTimeCalendar'
import getTimeCalendarList from './getTimeCalendarList'
import getTurn from './getTurn'
import getTurnList from './getTurnList'
import getUrgentClientOrderList from './getUrgentClientOrderList'
import getUrgentJobList from './getUrgentJobList'
import getUser from './getUser'
import getUserList from './getUserList'
import getVariant from './getVariant'
import getVariantList from './getVariantList'
import getWork from './getWork'
import getWorker from './getWorker'
import getWorkerList from './getWorkerList'
import getWorkList from './getWorkList'
import getWorkListFilter from './getWorkListFilter'
import getWorkListForDate from './getWorkListForDate'
import getWorkListSpan from './getWorkListSpan'
import getWorkOrder from './getWorkOrder'
import getWorkOrderList from './getWorkOrderList'
import jobListState from './jobListState'
import login from './login'
import loginEmployee from './loginEmployee'
import mergeJob from './mergeJob'
import mergeKit from './mergeKit'
import mergeMaster from './mergeMaster'
import mergeMaterial from './mergeMaterial'
import mergeMold from './mergeMold'
import mergePackaging from './mergePackaging'
import messages from './messages'
import organization from './organization'
import realtime from './realtime'
import recap from './recap'
import runReverseGeocoding from './runReverseGeocoding'
import saveService from './saveService'
import search from './search'
import sendNotification from './sendNotification'
import setArticleVariantOrdering from './setArticleVariantOrdering'
import sorts from './sorts'
import switchJob from './switchJob'
import toolbar from './toolbar'
import updateCost from './updateCost'
import updateJob from './updateJob'
import updateMachine from './updateMachine'
import updateMachineType from './updateMachineType'
import updateTask from './updateTask'
import updateWork from './updateWork'
import updateWorker from './updateWorker'
import uploadFile from './uploadFile'
import uploadImage from './uploadImage'
import uploadMultipleFiles from './uploadMultipleFiles'
import workday from './workday'
import createLabel from './createLabel'
import deleteLabel from './deleteLabel'
import getLabelList from './getLabelList'
import getLabel from './getLabel'
import getSessionData from './getSessionData'
import getSessionDataList from './getSessionDataList'

const AppReducer = combineReducers({
    /* @combine */
	getWorkOrderListSpan, 
	deleteRecipe, 
	createRecipe, 
	getRecipeList, 
	getRecipe, 
	getListingForWorker, 
	deleteListing, 
	createListing, 
	getListingList, 
	getListing, 
    createLabel,
    deleteLabel,
    getLabelList,
    getLabel,
    getSessionData,
    getSessionDataList,
    deleteProduction,
    createProduction,
    getProductionList,
    getProduction,
    deleteLot,
    createLot,
    getLotList,
    getLot,
    getSimpleArticleList,
    mergeMold,
    deleteMold,
    createMold,
    getMoldList,
    getMold,
    mergeKit,
    mergePackaging,
    mergeMaterial,
    mergeMaster,
    deleteCustomer,
    createCustomer,
    getCustomerList,
    getCustomer,
    deleteBadge,
    createBadge,
    getBadgeList,
    getBadge,
    deleteBadgeScan,
    createBadgeScan,
    getBadgeScanList,
    getBadgeScan,
    deleteTimeCalendar,
    createTimeCalendar,
    getTimeCalendarList,
    getTimeCalendar,
    deleteSchedule,
    createSchedule,
    getScheduleList,
    getSchedule,
    deleteInterval,
    createInterval,
    getIntervalList,
    getInterval,
    createArticleImport,
    deleteMaster,
    createMaster,
    getMasterList,
    getMaster,
    deleteKit,
    createKit,
    getKitList,
    getKit,
    deletePackage,
    createPackage,
    getPackageList,
    getPackage,
    deleteMaterial,
    createMaterial,
    getMaterialList,
    getMaterial,
    deleteCommand,
    createCommand,
    getCommandList,
    getCommand,
    deleteWorkOrder,
    createWorkOrder,
    getWorkOrderList,
    getWorkOrder,
    getClientOrder,
    clientOrderListState,
    getUrgentClientOrderList,
    getRecentClientOrderList,
    getClientOrderTypeList,
    getDetailedClientOrderList,
    getClientOrderList,
    deleteClientOrder,
    createClientOrder,
    cloneTasks,
    updateTask,
    getJobTaskList,
    deleteTask,
    createTask,
    getTaskList,
    getTask,
    getLastSWA300Data,
    getSWA300Data,
    getBabypressaDataFilter,
    getBabypressaData,
    getGT2000DataFilter,
    getGT2000Data,
    sorts,
    filters,
    runReverseGeocoding,
    clearParent,
    deleteArticleVariant,
    mergeJob,
    deleteMapping,
    createMapping,
    getDataImportList,
    getItemList,
    doAssignDuplicates,
    setArticleVariantOrdering,
    doAssignVariants,
    deleteOffice,
    createOffice,
    getOfficeList,
    getOffice,
    getArticleVariantImageList,
    getCollectionsFull,
    uploadMultipleFiles,
    deletePicture,
    createPicture,
    getPictureList,
    getPicture,
    deleteStdArticle,
    createStdArticle,
    getStdArticleList,
    getStdArticle,
    deleteVariant,
    createVariant,
    getVariantList,
    getVariant,
    deleteFamily,
    createFamily,
    getFamilyList,
    getFamily,
    deleteCollection,
    createCollection,
    getCollection,
    getCollectionList,
    messages,
    getMappingData,
    getUrgentJobList,
    deleteOrganization,
    createOrganization,
    getOrganizationList,
    getOrganization,
    deleteContract,
    createContract,
    getContractList,
    getContract,
    sendNotification,
    getArticleRecap,
    getProductRecap,
    getDocumentRecap,
    deleteDeposit,
    createDeposit,
    getDeposit,
    getCategory,
    getCorp,
    deleteCorp,
    createCorp,
    deleteCategory,
    createCategory,
    deleteUser,
    createProduct,
    getCorpList,
    getCategoryList,
    getProduct,
    deleteProduct,
    getProductList,
    getDepositList,
    getDocument,
    deleteDocument,
    createDocument,
    getDocumentList,
    getArticleList,
    getArticle,
    deleteArticle,
    createArticle,
    getTurn,
    deleteTurn,
    createTurn,
    getTurnList,
    getRoleList,
    getUser,
    changePassword,
    createUser,
    getUserList,
    organization,
    getFromIdSubidFiltering,
    getFromIdSubid,
    getFromIdFiltering,
    getFromId,
    recap,
    getRecentJobList,
    getRecentMachineList,
    saveService,
    deleteBlackbox,
    getBlackboxList,
    realtime,
    jobListState,
    deleteWorkList,
    getCost,
    getWorker,
    getMachine,
    getJob,
    getWorkListForDate,
    workday,
    assignWork,
    uploadFile,
    uploadImage,
    getImageList,
    getJobTypeList,
    search,
    toolbar,
    getJobList,
    getItemCosts,
    getWorkerList,
    deleteWorker,
    updateWorker,
    createWorker,
    getPagedCostList,
    getCostList,
    deleteCost,
    updateCost,
    createCost,
    getMachineTypeList,
    deleteMachineType,
    updateMachineType,
    createMachineType,
    getMachineList,
    deleteMachine,
    updateMachine,
    createMachine,
    getWorkListFilter,
    getWorkListSpan,
    getWorkList,
    getWork,
    deleteWork,
    updateWork,
    createWork,
    deleteJob,
    switchJob,
    updateJob,
    createJob,
    getDetailedJobList,
    getPagedJobList,
    loginEmployee,
    login,
    notifications,
})

const Reducer = (state, action) => {
    if (action.type === LOGIN_TYPES.logout || action.type === LOGIN_TYPES.request) state = undefined

    let newState = AppReducer(state, action)
    return newState
}

export default Reducer
