import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Dimmer, Icon, Loader, Menu, Modal } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { UPLOADMULTIPLEFILES_ACTIONS } from '../actions/uploadMultipleFiles'
import { buildFileSelector } from '../lib/util'
import ImageDefault from './ImageDefault'

class PickMultipleImage extends Component {
    constructor(props) {
        super(props)
        let id = props.id

        this.state = {
            page: 0,
            items: 12,
            imageArray: this.props.value ? this.props.value : [],
            fetching: true,
            images: [],
            showModal: false,
            fileSelector: buildFileSelector(this.handleUpload),
        }
    }

    componentDidMount() {
        let { page, items } = this.state
        this.props.network.getPictureList(page, items)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                imageArray: this.props.value,
            })
        }

        if (this.state.page != prevState.page || this.state.items != prevState.items) {
            this.state.network.getPictureList(this.state.page, this.state.items)
        }
    }

    pickImage(image) {
        // console.log('Pick image', image)
        if (!image) return

        let { onChange } = this.props
        let { imageArray } = this.state

        let toDelete = null
        for (const i in imageArray) {
            const obj = imageArray[i]
            if (obj.pictureId === image.pictureId) {
                toDelete = i
                break
            }
        }

        if (toDelete) {
            imageArray.splice(toDelete, 1)
        } else {
            imageArray.push(image)
        }

        if (onChange) {
            onChange(imageArray)
        }

        this.setState({
            imageArray,
        })
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    closeModal = () => {
        this.setState({ showModal: false })
    }

    uploadImage = () => {
        this.state.fileSelector.click()
    }

    handleUpload = (event, name) => {
        //console.log('Handle upload called');
        let obj = { loaded: 0 }
        obj[name] = event.target.files[0]
        this.setState(obj)

        this.props.network.uploadMultipleFiles(event.target.files, (result) => {
            const { files } = result
            for (const file of files) {
                this.pickImage(file)
            }
            this.closeModal()
            this.setState({ result })
        })
    }

    isImageSelected = (image) => {
        let { imageArray } = this.state
        let selected = false

        for (const i in imageArray) {
            const obj = imageArray[i]
            if (obj.pictureId === image.pictureId) {
                selected = true
                break
            }
        }
        return selected
    }

    componentWillUnmount() {
        this.props.uploadMultipleFilesError({})
    }

    render() {
        let { fetching, images, selectedIndex } = this.props
        let { imageArray, readonly } = this.state

        return (
            <Modal
                onClose={this.closeModal}
                open={this.state.showModal}
                trigger={
                    <>
                        {!readonly && (
                            <View style={{ margin: 16 }} fullw between>
                                <Button style={{ height: 64 }} onClick={() => this.setState({ showModal: true })}>
                                    {this.props.singleImage ? 'Carica immagine' : 'Carica immagini'}
                                </Button>
                            </View>
                        )}
                        <View fullw wrap around>
                            {imageArray.map((value, index) => {
                                const extraProps = {}
                                if (this.props.onImageClick) {
                                    extraProps.onClick = () => {
                                        if (this.props.onImageClick) {
                                            this.props.onImageClick(value, index)
                                        }
                                    }
                                }

                                return (
                                    <Box
                                        key={`pick-multiple-image-box-${value.picturePath}`}
                                        {...extraProps}
                                        style={{
                                            width: 360,
                                            height: 360,
                                            margin: 8,
                                        }}
                                    >
                                        <ImageDefault
                                            key={index}
                                            fromRoot
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}
                                            src={value.picturePath}
                                        />

                                        {this.props.selectedIndex !== undefined && this.props.selectedIndex === index && (
                                            <div
                                                style={{
                                                    width: '32px',
                                                    height: '32px',
                                                    background: 'rgba(99, 177, 255, 0.60)',
                                                    zIndex: 2,
                                                }}
                                            />
                                        )}

                                        {!readonly && (
                                            <Button
                                                style={{
                                                    right: 0,
                                                    bottom: 0,
                                                    zIndex: 3,
                                                }}
                                                color="red"
                                                onClick={(e) => {
                                                    this.pickImage(value)
                                                    e.stopPropagation()
                                                }}
                                            >
                                                <Icon name="trash" />
                                                Elimina
                                            </Button>
                                        )}
                                    </Box>
                                )
                            })}
                        </View>
                    </>
                }
                closeIcon
            >
                <Modal.Header>Scelta immagine</Modal.Header>
                <Modal.Content image scrolling style={{ padding: 0 }}>
                    <View fullw column>
                        <Menu style={{ borderRadius: '0px' }}>
                            <Menu.Item as="a" icon="upload" name="Carica immagine" onClick={this.uploadImage} position={'left'} />
                            <Menu.Item
                                as="a"
                                icon="arrow left"
                                onClick={() => {
                                    this.prev()
                                }}
                                position={'right'}
                            />
                            <Menu.Item
                                as="a"
                                icon="arrow right"
                                onClick={() => {
                                    this.next()
                                }}
                                position={'none'}
                            />
                        </Menu>
                        <Modal.Description style={{ maxWidth: '100%' }}>
                            {!fetching && (
                                <View fullw wrap row around style={{ padding: 16 }}>
                                    {images &&
                                        images.map((value, index) => (
                                            <Box
                                                key={value + index}
                                                onClick={() => {
                                                    this.pickImage(value)
                                                }}
                                                style={{
                                                    width: '200px',
                                                    height: '200px',
                                                    marginTop: 16,
                                                    marginBottom: 16,
                                                }}
                                            >
                                                <ImageDefault
                                                    fromRoot
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                    src={value.picturePath}
                                                    ui={false}
                                                />
                                                {this.isImageSelected(value) && (
                                                    <div
                                                        style={{
                                                            width: '32px',
                                                            height: '32px',
                                                            background: 'rgba(99, 177, 255, 0.60)',
                                                            zIndex: 2,
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        ))}
                                </View>
                            )}
                            {fetching && (
                                <Box p={6} style={{ minHeight: '600px' }}>
                                    <Dimmer active inverted>
                                        <Loader inverted />
                                    </Dimmer>
                                </Box>
                            )}
                        </Modal.Description>
                    </View>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={this.closeModal}>
                        Ok <Icon name="check" />
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getPictureList: {
            data: { data },
            fetching,
        },
    } = state

    return {
        images: data,
        fetching,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

        uploadMultipleFilesRequest: (result) => UPLOADMULTIPLEFILES_ACTIONS.request(result, dispatch),
        uploadMultipleFilesResult: (result) => UPLOADMULTIPLEFILES_ACTIONS.result(result, dispatch),
        uploadMultipleFilesError: (result) => UPLOADMULTIPLEFILES_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(PickMultipleImage)))
