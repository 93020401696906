import { connectNetwork } from 'lib/NetworkProvider'
import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar, PieChart, Pie, Cell, Legend } from 'recharts'
import { baseViewDispatch, initializePage, timeObject } from '../lib/util'
import moment from 'moment'
import { connect } from 'react-redux'
import { Box, Heading, SimpleGrid } from '@chakra-ui/react'
import { getWorkListSpanwPieces } from 'lib/requests/getWorkListSpanwPieces'

const tickFormatter = (time) => moment(time).format('YYYY-MM-DD')

const formatTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    return `${hours.toString().padStart(2, '0')} ore e ${minutes.toString().padStart(2, '0')} minuti`
}

const chartWidth = 800
const chartHeight = 300
const COLORS = ['#0088FE', '#FF8042']

const DataVisualizationComponent = ({ network, isEmployee, workday, worksByDay, workordersByDay, machines, machinesById, workers, workersById }) => {
    const [startDate, setStartDate] = useState(moment().startOf('month').toDate())
    const [endDate, setEndDate] = useState(moment().endOf('month').toDate())
    const [chartData, setChartData] = useState([])
    const [chartDataExtra, setChartDataExtra] = useState([])
    const [worker, setWorker] = useState(null)
    const [totalPieces, setTotalPieces] = useState(0)
    const [badPieces, setBadPieces] = useState(0)

    // Fetch data when date range changes
    useEffect(() => {
        // Fetch data based on startDate and endDate
        // setChartData(fetchedData);
        network.getMachineList().then(() => {
            network.getWorkerList().then(() => {
                network
                    .getWorkListSpan(
                        moment(startDate).format('YYYY-MM-DD'),
                        moment(endDate).format('YYYY-MM-DD'),
                        worker ? worker.id : null,
                        !!isEmployee
                    )
                    .then((result) => {
                        const newChartData = []
                        let totalBad = 0
                        for (const key in result.data) {
                            for (const obj of result.data[key]) {
                                obj.netCycles = obj.endCycles - obj.startCycles
                                totalBad += obj.badPieces
                                obj.time = moment(obj.workStart, 'YYYY-MM-DD HH:mm:ss').valueOf()
                                newChartData.push(obj)
                            }
                        }
                        setBadPieces(totalBad)
                        setChartData(newChartData)
                    })
            })
        })

        network
            .getWorkListSpanwPieces(
                moment(startDate).format('YYYY-MM-DD'),
                moment(endDate).format('YYYY-MM-DD'),
                worker ? worker.id : null,
                !!isEmployee
            )
            .then((result) => {
                /* eslint-disable */
                console.log('worklistspanwpieces result is', result)
                const newChartData = []
                let total = 0
                for (const key in result.data) {
                    for (const obj of result.data[key]) {
                        obj.netCycles = obj.endCycles - obj.startCycles
                        obj.netPieces = obj.netCycles * obj.piecesPerCycle
                        console.log('worklistspanwpieces calcing', obj.netCycles, obj.piecesPerCycle, obj.netCycles * obj.piecesPerCycle)
                        total += obj.netPieces
                        obj.time = moment(obj.workStart, 'YYYY-MM-DD HH:mm:ss').valueOf()
                        newChartData.push(obj)
                    }
                }
                setTotalPieces(total)
                setChartDataExtra(newChartData)
            })
            .catch((error) => {
                console.error('error', error)
            })
    }, [startDate, endDate])

    const aggregateData = chartData.reduce((acc, data) => {
        if (acc[data.badReason]) {
            acc[data.badReason].badPieces += data.badPieces // Summing badPieces for the same badReason
        } else {
            acc[data.badReason] = { ...data }
        }
        return acc
    }, {})

    const workTimePerDay = chartData.reduce((acc, data) => {
        const day = moment(data.workDate).format('YYYY-MM-DD')
        const totalMinutes = data.machineHours * 60 + data.machineMinutes

        if (!acc[day]) {
            acc[day] = 0
        }
        acc[day] += totalMinutes

        return acc
    }, {})

    const workTimePerMachine = chartData.reduce((acc, data) => {
        if (!acc[data.machineId]) {
            acc[data.machineId] = 0
        }
        acc[data.machineId] += data.machineHours * 60 + data.machineMinutes

        return acc
    }, {})

    const employeeWorkTimePerDay = chartData.reduce((acc, data) => {
        const day = moment(data.workDate).format('YYYY-MM-DD')
        const totalMinutes = data.workerHours * 60 + data.workerMinutes

        if (!acc[day]) {
            acc[day] = 0
        }
        acc[day] += totalMinutes

        return acc
    }, {})

    const workTimePerEmployee = chartData.reduce((acc, data) => {
        if (!acc[data.workerId]) {
            acc[data.workerId] = 0
        }
        acc[data.workerId] += data.workerHours * 60 + data.workerMinutes

        return acc
    }, {})

    const badData = []
    const machineTimePerDay = []
    const machineTimePerMachine = []
    const workerTimePerDay = []
    const workerTimePerWorker = []
    for (const key in aggregateData) {
        badData.push({
            badPieces: aggregateData[key].badPieces,
            badReason: aggregateData[key].badReason,
        })
    }
    for (const key in workTimePerDay) {
        machineTimePerDay.unshift({
            workTime: workTimePerDay[key],
            time: key,
            day: key,
        })
    }
    for (const key in workTimePerMachine) {
        machineTimePerMachine.push({
            workTime: workTimePerMachine[key],
            machineId: machinesById[key].machineName,
        })
    }
    for (const key in employeeWorkTimePerDay) {
        workerTimePerDay.unshift({
            workTime: workTimePerDay[key],
            time: key,
            day: key,
        })
    }
    for (const key in workTimePerEmployee) {
        workerTimePerWorker.push({
            workTime: workTimePerEmployee[key],
            workerId: workersById[key].workerName,
        })
    }

    /* eslint-disable */
    console.log('chartData', workerTimePerDay, workerTimePerWorker)

    const qualityData = [
        { name: 'Pezzi buoni: ' + (totalPieces - badPieces), value: Math.round(((totalPieces - badPieces) / totalPieces) * 100 * 100) / 100 },
        { name: 'Scarti: ' + badPieces, value: Math.round((badPieces / totalPieces) * 100 * 100) / 100 },
    ]

    return (
        <Box p={14}>
            <Box mb={8}>
                <label>Data di inizio: </label>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                <label>Data di fine: </label>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
            </Box>
            <SimpleGrid columns={{ sm: 1, md: 1 }} spacing={10}>
                <Box>
                    <Heading mb={3}>Scarti per Causale</Heading>
                    <BarChart width={chartWidth} height={chartHeight} data={badData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="badReason" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="badPieces" fill="#8884d8" name="Scarti per causale" />
                    </BarChart>
                </Box>
                <Box>
                    <Heading mb={3}>Cicli Macchina</Heading>
                    <LineChart width={chartWidth} height={chartHeight} data={chartData}>
                        <Line type="monotone" dataKey="netCycles" stroke="#8884d8" name="Cicli macchina" />
                        <CartesianGrid stroke="#ccc" />
                        <XAxis dataKey="time" tickFormatter={tickFormatter} />
                        <YAxis />
                        <Tooltip />
                    </LineChart>
                </Box>
                <Box>
                    <Heading mb={3}>Assicurazione qualità</Heading>
                    <PieChart width={400} height={400}>
                        <Pie dataKey="value" isAnimationActive={false} data={qualityData} cx={200} cy={200} outerRadius={80} fill="#8884d8" label>
                            {qualityData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </Box>
                <Box>
                    <Heading mb={3}>Scarti nel Tempo</Heading>
                    <LineChart width={chartWidth} height={chartHeight} data={chartData}>
                        <Line type="monotone" dataKey="badPieces" stroke="#82ca9d" name="Scarti nel tempo" />
                        <CartesianGrid stroke="#ccc" />
                        <XAxis dataKey="time" tickFormatter={tickFormatter} />
                        <YAxis />
                        <Tooltip />
                    </LineChart>
                </Box>
                <Box>
                    <Heading mb={3}>Tempo macchina per giorno</Heading>
                    <BarChart width={chartWidth} height={chartHeight} data={machineTimePerDay}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis tickFormatter={formatTime} />
                        <Tooltip formatter={(value) => formatTime(value)} />
                        <Bar dataKey="workTime" fill="#82ca9d" name="Tempo di lavoro Macchina per Giorno" />
                    </BarChart>
                </Box>
                <Box>
                    <Heading mb={3}>Tempo macchina</Heading>
                    <BarChart width={chartWidth} height={chartHeight} data={machineTimePerMachine}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="machineId" />
                        <YAxis tickFormatter={formatTime} />
                        <Tooltip formatter={(value) => formatTime(value)} />
                        <Bar dataKey="workTime" fill="#8884d8" name="Tempo di lavoro per Macchina" />
                    </BarChart>
                </Box>
                <Box>
                    <Heading mb={3}>Tempo impiegato per giorno</Heading>
                    <BarChart width={chartWidth} height={chartHeight} data={workerTimePerDay}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis tickFormatter={formatTime} />
                        <Tooltip formatter={(value) => formatTime(value)} />
                        <Bar dataKey="workTime" fill="#82ca9d" name="Tempo di lavoro Impiegato per Giorno" />
                    </BarChart>
                </Box>
                <Box>
                    <Heading mb={3}>Tempo impiegato</Heading>
                    <BarChart width={chartWidth} height={chartHeight} data={workerTimePerWorker}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="workerId" />
                        <YAxis tickFormatter={formatTime} />
                        <Tooltip formatter={(value) => formatTime(value)} />
                        <Bar dataKey="workTime" fill="#8884d8" name="Tempo di lavoro per Impiegato" />
                    </BarChart>
                </Box>
            </SimpleGrid>
        </Box>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        workday: {
            data: { date: workday },
        },
        getWorkListSpan: {
            data: { data: worksByDay },
        },
        getWorkOrderListSpan: {
            data: { data: workordersByDay },
        },
        getMachineList: {
            fetching: fetchMachines,
            data: { data: machines },
        },
        getWorkerList: {
            fetching: fetchWorkers,
            data: { data: workers },
        },
    } = state

    const machinesById = {}
    if (machines) {
        for (const machine of machines) {
            machinesById[machine.machineId] = machine
        }
    }

    const workersById = {}
    if (workers) {
        for (const worker of workers) {
            workersById[worker.workerId] = worker
        }
    }

    return {
        workday,
        worksByDay,
        workordersByDay,
        machines,
        machinesById,
        workers,
        workersById,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(DataVisualizationComponent)))
