import React from 'react'
import { connectNetwork } from 'lib/NetworkProvider'
import 'moment/locale/it'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from 'lib/View'
import Iframe from 'react-iframe'
import { Form, Select } from 'semantic-ui-react'

const sidebarOptions = [
    {
        key: 'normalmenu',
        text: 'Generale',
        value: 'normalmenu',
    },
    {
        key: 'estegomenu',
        text: 'Vetrina',
        value: 'estegomenu',
    },
    {
        key: 'vfmenu',
        text: 'Stampaggio generale',
        value: 'vfmenu',
    },
    {
        key: 'krilamenu',
        text: 'Stampaggio ridotto',
        value: 'krilamenu',
    },
]

function Preferences({ toolbarResult, filtersResult, sortsResult, network, machines }) {
    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    const preferenceSidebar = localStorage.getItem('preferences.sidebar')
    const [selectedMenu, setSelectedMenu] = React.useState(preferenceSidebar ? preferenceSidebar : 'vfmenu')

    return (
        <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Form style={{ margin: 8, marginBottom: 42 }}>
                <Form.Field
                    id="form-input-control-sidebar"
                    control={Select}
                    options={sidebarOptions}
                    label={<span className="label">Tipo menù laterale</span>}
                    placeholder="Tipo commessa"
                    name="jobType"
                    value={selectedMenu}
                    onChange={(e, data) => {
                        let selectedMenu = data.value
                        setSelectedMenu(selectedMenu)
                        localStorage.setItem('preferences.sidebar', selectedMenu)
                        window.location.reload()
                    }}
                />
            </Form>
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Preferences)))
