import { connectNetwork } from 'lib/NetworkProvider'
import React from 'react'
import ReactExport from 'react-data-export'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Loader } from 'semantic-ui-react'
import moment from 'momentconfig'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class TimeRecap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            excelfile: [],
        }
    }

    componentDidMount() {
        let { network } = this.props
        const startMom = moment().startOf('week')
        const endMom = moment().endOf('week')
        let start = startMom.format('YYYY-MM-DD HH:mm:ss')
        let end = endMom.format('YYYY-MM-DD HH:mm:ss')

        const weekdata = {}

        network.getWorkerList('').then((workerlistdata) => {
            network.getBadgeScanList(start, end).then((badgescanlistdata) => {
                const workerlist = workerlistdata.data
                const badgescanlist = badgescanlistdata.data
                this.setState({ loaded: true })

                const badgeScansPerDate = {}
                for (const badgescan of badgescanlist) {
                    const datestring = moment(badgescan.badgeScanTime).format('YYYYMMDD')
                    const worker = badgescan.workerId

                    if (!badgeScansPerDate[datestring]) {
                        badgeScansPerDate[datestring] = {}
                    }

                    if (!badgeScansPerDate[datestring][worker]) {
                        badgeScansPerDate[datestring][worker] = []
                    }

                    badgeScansPerDate[datestring][worker].push(badgescan)
                }

                let curMom = startMom
                while (curMom.isBefore(endMom)) {
                    const datestring = curMom.format('YYYYMMDD')
                    if (!weekdata[datestring]) {
                        weekdata[datestring] = {}
                    }
                    for (const worker of workerlist) {
                        if (!weekdata[datestring][worker.workerId]) {
                            weekdata[datestring][worker.workerId] = {
                                seconds: 0,
                            }
                        }

                        if (badgeScansPerDate[datestring] && badgeScansPerDate[datestring][worker.workerId]) {
                            const badgescansForDay = badgeScansPerDate[datestring][worker.workerId]
                            let lastScan = null
                            for (const badgescan of badgescansForDay) {
                                const badgescanmom = moment(badgescan.badgeScanTime)
                                if (!lastScan) {
                                    lastScan = badgescan
                                } else {
                                    const lastscanmom = moment(lastScan.badgeScanTime)
                                    const diff = badgescanmom.unix() - lastscanmom.unix()
                                    if (diff < 600) {
                                        continue
                                    } else {
                                        weekdata[datestring][worker.workerId].seconds += diff
                                        lastScan = null
                                    }
                                }
                            }
                        }
                    }
                    curMom = curMom.add(1, 'day')
                }

                const excelfile = []
                for (const worker of workerlist) {
                    const row = {
                        workerId: worker.workerId,
                        workerName: worker.workerName,
                    }
                    for (const datestring in weekdata) {
                        if (weekdata[datestring][worker.workerId]) {
                            row[datestring] = Math.round((weekdata[datestring][worker.workerId].seconds / 3600) * 10) / 10
                        } else {
                            row[datestring] = 0
                        }
                    }
                    excelfile.push(row)
                }

                this.setState({ badgeScansPerDate, weekdata, excelfile })
            })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    render() {
        const { element } = this.props
        let { loaded, excelfile, weekdata } = this.state

        if (!loaded) {
            return <Loader active />
        }

        return (
            <ExcelFile filename={`smarcatempo_settimana_${moment().get('week')}`} element={element ? element : <Button>Download</Button>}>
                <ExcelSheet data={excelfile} name="Riepilogo">
                    <ExcelColumn label="Impiegato" value="workerName" />
                    {weekdata &&
                        Object.keys(weekdata).map((value, index) => {
                            return <ExcelColumn key={`date-${value}`} label={moment(value, 'YYYYMMDD').format('DD/MM/YYYY')} value={value} />
                        })}
                </ExcelSheet>
            </ExcelFile>
        )
    }
    /*
    <ExcelColumn label={<span className="label">Name" value="name</span>} />
    <ExcelColumn label={<span className="label">Wallet Money" value="amount</span>} />
    <ExcelColumn label={<span className="label">Gender" value="sex</span>} />
    <ExcelColumn label={<span className="label">Marital Status</span>} value={(col) => (col.is_married ? 'Married' : 'Single')} />
    */
}

const mapStateToProps = (state) => {
    let { login, recap, getWorkerList, getMachineList } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, recap, getWorkerList, getMachineList }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(TimeRecap)))
